import React from "react"
import { graphql } from "gatsby"

import StaticPage from "../templates/StaticPage"

const Roadmap = ({ data }) => {
  const { html, frontmatter = {} } = data?.roadmap || {}
  const { edges = [] } = data?.blog || {}

  return (
    <StaticPage
      title={frontmatter.title}
      description={frontmatter.description}
      html={html}
    >
      <h2>In Progress Tutorials</h2>
      <ul>
        {edges.map(({ node }) => (
          <li key={node.id}>{node.frontmatter.title}</li>
        ))}
      </ul>
    </StaticPage>
  )
}

export const query = graphql`
  {
    roadmap: markdownRemark(fileAbsolutePath: { regex: "/pages/roadmap/" }) {
      html
      frontmatter {
        title
        description
      }
    }

    blog: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/blog/**" }
        frontmatter: { published: { eq: false } }
      }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
          }
        }
      }
    }
  }
`

export default Roadmap
